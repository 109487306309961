.mapboxgl-ctrl-attrib-inner {
  display: none;
  visibility: hidden;
}
.mapboxgl-canvas-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
